































import Vue from "vue";
import axios from "axios";
import store from "/front/src/store/WebUI.ts";
import LocalServerConfig from "@/components/WebUI/Accounts/LocalServerConfig.vue"
import Help from "@/components/WebUI/Accounts/Help.vue"
import CameraConfig from "@/components/WebUI/Accounts/CameraConfig.vue"
import AlertTest from "@/components/WebUI/Accounts/AlertTest.vue"
import ErrorLog from "@/components/WebUI/Accounts/ErrorLog.vue"

interface Data {
  result:any[];
  parkings:any[];
  localServerConfig:any[];
  localServerConfigLoading:boolean;
  cameraConfig:any[];
  cameraConfigLoading:boolean;
  exitEntranceInfo:any[];
  alertMailAddress:any[];
  alertTestLoading:boolean;
  errorLog:any[];
  errorLogLoading:boolean;
}
export default Vue.extend({
  data(): Data {
    return {
      result:[],
      parkings:[],
      localServerConfig:[],
      localServerConfigLoading:false,
      cameraConfig:[],
      cameraConfigLoading:false,
      exitEntranceInfo:[],
      alertMailAddress:[],
      alertTestLoading:false,
      errorLog:[],
      errorLogLoading:false,
    }
  },
  components:{
    LocalServerConfig,
    Help,
    CameraConfig,
    AlertTest,
    ErrorLog
  },
  watch: {
    selectServer(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.getLocalServerConfig();
        this.getCameraConfig();
        this.getExitEntranceInfo();
        this.getAlertMailAddress();
        this.getErrorLog(false);
      }
    }
  },
  mounted() {
    this.getLocalServerConfig();
    this.getCameraConfig();
    this.getExitEntranceInfo();
    this.getAlertMailAddress();
    this.getErrorLog(false);
  },
  methods: {
    async getErrorLog(allSelect:boolean): Promise<void> {
      console.log(allSelect)
      console.log("getErrorLog")
      this.errorLog = [];
      if (this.selectServer) {
        this.errorLogLoading = true;
        const url = `${process.env.VUE_APP_API_URL_BASE}/api/web_ui/error_log`;
        return await axios
        .get(url, { params: { id: this.selectServer, allSelect: allSelect } })
        .then((res:any) => {
          this.errorLog = res.data;
        })
        .catch((error: any) => {
          console.log(error)
          alert("物件への接続に失敗しました");
          this.errorLog = [];
        })
        .finally(() => {
          this.errorLogLoading = false;
        });
      }
    },
    async getAlertMailAddress(): Promise<void> {
      console.log("getAlertMailAddress")
      this.alertMailAddress = [];
      if (this.selectServer) {
        this.alertTestLoading = true;
        const url = `${process.env.VUE_APP_API_URL_BASE}/api/web_ui/alert_mail_address`;
        return await axios
        .get(url, { params: { id: this.selectServer } })
        .then((res:any) => {
          this.alertMailAddress = res.data;
        })
        .catch((error: any) => {
          console.log(error)
          alert("物件への接続に失敗しました");
          this.alertMailAddress = [];
        })
        .finally(() => {
          this.alertTestLoading = false;
        });
      }
    },
    async getLocalServerConfig(): Promise<void> {
      console.log("getLocalServerConfig")
      this.localServerConfig = [];
      if (this.selectServer) {
        this.localServerConfigLoading = true;
        const url = `${process.env.VUE_APP_API_URL_BASE}/api/web_ui/account_mgr`;
        return await axios
        .get(url, { params: { id: this.selectServer } })
        .then((res:any) => {
          this.localServerConfig = res.data;
        })
        .catch((error: any) => {
          console.log(error)
          alert("物件への接続に失敗しました");
          this.localServerConfig = [];
        })
        .finally(() => {
          this.localServerConfigLoading = false;
        });
      }
    },
    async getCameraConfig(): Promise<void> {
      console.log("getCameraConfig")
      this.cameraConfig = [];
      if (this.selectServer) {
        this.cameraConfigLoading = true;
        const url = `${process.env.VUE_APP_API_URL_BASE}/api/web_ui/recognizer_camera_settings`;
        return await axios
        .get(url, { params: { id: this.selectServer } })
        .then((res:any) => {
          this.cameraConfig = res.data;
        })
        .catch((error: any) => {
          console.log(error)
          alert("物件への接続に失敗しました");
          this.cameraConfig = [];
        })
        .finally(() => {
          this.cameraConfigLoading = false;
        });
      }
    },
    async getExitEntranceInfo(): Promise<void> {
      console.log("getExitEntranceInfo")
      this.exitEntranceInfo = [];
      if (this.selectServer) {
        this.$vloading.show();
        const url = `${process.env.VUE_APP_API_URL_BASE}/api/web_ui/exit_entrance_info`;
        return await axios
        .get(url, { params: { id: this.selectServer } })
        .then((res:any) => {
          this.exitEntranceInfo = res.data;
        })
        .catch((error: any) => {
          console.log(error)
          alert("物件への接続に失敗しました");
        })
        .finally(() => {
          this.$vloading.hide();
        });
      }
    },
  },
  computed: {
    selectServer() {
      return store.state.WebUI.server;
    }
  },
});
