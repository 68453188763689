













































































































import Vue from "vue";
import axios from "axios";
import store from "/front/src/store/WebUI.ts";
interface Data {
  object:any[] | null;
  kindItem:any[];
  address:string;
}
export default Vue.extend({
  data(): Data {
    return {
      object: null,
      kindItem:[
        {text:"未払い", value:0},
        {text:"店舗指定", value:1},
        {text:"警察指定", value:2},
        {text:"その他", value:3}
      ],
      address:"",
    }
  },

  props:{
    updateObject:{},
    updateLoading:{}
  },
  watch: {
    updateObject: {
      deep: true,
      immediate: true,
      handler(newVal) {
        this.object = { ...newVal };
      }
    }
  },

  methods: {
    // メールアドレスを登録する
    pushAddress(value: string) {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
      if (!emailRegex.test(value)) {
        console.log(`${value} は無効なメールアドレスです。`);
        return; // 正しくない場合は処理を終了
      }
      if (this.object.MAIL_ADDRESS == null) {
        this.object.MAIL_ADDRESS = [];
        this.object.MAIL_ADDRESS.push(value);
        console.log(`${value} が登録されました。`);
      } else {
        if (this.object.MAIL_ADDRESS.includes(value)) {
          console.log(`${value} はすでに登録済み。`);
        } else {
          this.object.MAIL_ADDRESS.push(value);
          console.log(`${value} が登録されました。`);
        }
      }
      this.address = "";
    },
    // メールアドレスを削除する
    removeAddress(value:string){
      this.object.MAIL_ADDRESS = this.object.MAIL_ADDRESS.filter(item => item !== value);
    },
    // 更新する
    async update(
      id:number,
      parkingId:number,
      place:string,
      classNumber:string,
      kana:string,
      carNumber:string,
      kindId:number,
      alertTitle:string,
      alertText:string,
      note:string,
      addresses:any
    ): Promise<void> {
      if (!this.selectServer) { alert("物件を選択してください"); this.result = []; return; }
      this.$emit('loading', true);
      const url = `${process.env.VUE_APP_API_URL_BASE}/api/web_ui/black_list_mgr/${id}`;
      const formData = {
        SERVER_ID: this.selectServer,
        PARKING_ID: parkingId,
        PLACE: place,
        CLASS_NUMBER: classNumber,
        KANA: kana,
        CAR_NUMBER: carNumber,
        KIND_ID: kindId,
        ALERT_TITLE: alertTitle,
        ALERT_TEXT: alertText,
        NOTE: note,
        MAIL_ADDRESS: addresses
      }
      return await axios
      .patch(url, formData)
      .then((res:any) => {
        if (res.data.error){ alert(res.data.message); return;}; 
        this.$emit('reload');
        this.$emit('reset');
        this.object = {};
        this.$emit('close');
        alert("成功しました");
      })
      .catch((error: any) => {
        alert(error);
      })
      .finally(() => {
        this.$emit('loading', false);
      });
    },
  },
  computed: {
    selectServer() {
      return store.state.WebUI.server;
    }
  }
});
