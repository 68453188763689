


















































































import Vue from "vue";
import axios from "axios";
import store from "/front/src/store/WebUI.ts";
interface Data {
  result:any[];
  dates:any[];
  parkingName01:any[],
  parkingName02:any[],
  parkingName03:any[],
}
export default Vue.extend({
  data(): Data {
    return {
      result:[],
      dates:[],
      parkingName01:[],
      parkingName02:[],
      parkingName03:[],
    }
  },
  watch: {
    selectServer(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.getResult();
        this.dates = [];
      }
    }
  },
  mounted() {
    this.getResult()
  },
  methods: {
    async destroy(
      id:number,
      holiday:string,
    ): Promise<void> {
      console.log(id)
      console.log(holiday)
      if (!this.selectServer) { alert("物件を選択してください"); this.result = []; return; }
      if (!window.confirm('削除しますか？')) return
      this.$vloading.show();
      const url = `${process.env.VUE_APP_API_URL_BASE}/api/web_ui/calendar_holiday_mas/${id}`;
      return await axios
      .delete(url, { params: { serverId: this.selectServer, HOLIDAY: holiday } })
      .then(() => {
        window.scroll({
          top: 0
        });
        alert("削除しました");
      })
      .catch((error: any) => {
        console.log(error)
        alert("削除に失敗しました");
      })
      .finally(() => {
        this.getResult();
        this.$vloading.hide();
      });
    },
    async send(
      id:number,
      holidays:any[],
    ): Promise<void> {
      if (holidays.length == 0) { alert("設定する日付を選択してください"); return; }
      if (!this.selectServer) { alert("物件を選択してください"); this.result = []; return; }
      if (!window.confirm('更新しますか？')) return
      this.$vloading.show();
      const url = `${process.env.VUE_APP_API_URL_BASE}/api/web_ui/calendar_holiday_mas`;
      const formData = {
        serverId: this.selectServer,
        id: id,
        holidays: holidays,
      };
      return await axios
      .post(url, formData)
      .then(() => {
        window.scroll({
          top: 0
        });
        alert("更新しました");
      })
      .catch((error: any) => {
        console.log(error)
        alert("更新に失敗しました");
      })
      .finally(() => {
        this.getResult();
        this.$vloading.hide();
      });
    },
    async getResult(): Promise<void> {
      if (!this.selectServer) { alert("物件を選択してください"); this.result = []; return; }
      this.$vloading.show();
      const url = `${process.env.VUE_APP_API_URL_BASE}/api/web_ui/calendar_holiday_mas`;
      return await axios
      .get(url, {params: { id: this.selectServer }} )
      .then((res:any) => {
        this.result = [];
        this.result = res.data.flat()
        this.result.forEach(item => {
          if (item.holidays != null) {
            item.holidays = item.holidays.split(',');
          }
          this.parkingName01.push(item.PARKING_NAME_1);
          this.parkingName02.push(item.PARKING_NAME_2);
          this.parkingName03.push(item.PARKING_NAME_3);
        });
      })
      .catch((error: any) => {
        console.log(error)
        alert("物件への接続に失敗しました");
        this.result = [];
      })
      .finally(() => {
        this.$vloading.hide();
      });
    },
  },
  computed: {
    selectServer() {
      return store.state.WebUI.server;
    }
  }
});
