














































































import Vue from "vue";
import axios from "axios";
import store from "/front/src/store/WebUI.ts";
interface Data {
  propsObject: any[];
  addressList: any[];
  dialog: boolean;
}
export default Vue.extend({
  data(): Data {
    return {
      propsObject: [],
      addressList: [],
      dialog: false,
    }
  },
  methods: {
    openAddressList(item:any){
      this.addressList = item;
      this.dialog = true;
    },
    async send(
      id:number,
    ): Promise<void> {
      if (!window.confirm('アラートメールを送信しますか？')) return
      this.$vloading.show();
      const url = `${process.env.VUE_APP_API_URL_BASE}/api/web_ui/alert_mail_address/send_a_test_email`;
      const formData = {
        serverId: this.selectServer,
        id: id
      };
      return await axios
      .post(url, formData)
      .then((res: any) => {
        if (res.data.success){
          alert("送信しました");
        } else {
          console.log(res.data)
          alert("送信に失敗しました（ローカルエラー）");
        }
      })
      .catch((error: any) => {
        console.log(error)
        alert("送信に失敗しました（Webエラー）");
      })
      .finally(() => {
        this.$vloading.hide();
      });
    },
  },
  props:{
    alertMailAddress: {},
    loading:{},
  },
  watch: {
    alertMailAddress: {
      handler(newVal) {
        this.propsObject = newVal; // 新しい値を反映
      },
      deep: true, // オブジェクトの深い変更を監視
      immediate: true // 初回レンダリング時にも監視
    }
  },
  computed: {
    selectServer() {
      return store.state.WebUI.server;
    },
  },
});
