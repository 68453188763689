<template>
  <v-container class="grey lighten-5">

    <div class="vehiclesinformation-title">
      <v-icon class="icon" dark x-large color="orange">mdi-account-plus</v-icon><span class="text">ユーザー新規登録</span>
    </div>

    <div class="box11" v-if="roleId == 1 || roleId == 2">
      <div class="box11-text"><v-icon class="ma-1" large>mdi-email</v-icon>1:管理者 / 2:社員 招待の流れ（招待メールの送信）</div>
      <div class="box11-sub-text">・メールアドレスを入力して「送信」を選択すると招待メールが送信されます。</div>
      <div class="box11-sub-text">・招待メールが送信された側は、メールに添付してあるURLから自身のアカウントパスワードを更新します。</div>
      <div class="box11-sub-text">・アカウントのパスワードが更新完了するまで、コモンズマネジメントにログインすることは出来ません。</div>
      <div class="box11-sub-text">・アカウントのパスワードの更新が終わった後のアカウントでも招待メールを送信することが出来ます。（パスワードを忘れた時に利用してください）</div>
    </div>

    <div class="box11" v-if="roleId == 4">
      <div class="box11-text"><v-icon class="ma-1" large>mdi-account</v-icon>4:アルバイト 招待の流れ（URL発行）</div>
      <div class="box11-sub-text">・アカウントIDを入力して「送信」を選択すると、URLが発行されます。</div>
      <div class="box11-sub-text">・招待したいメンバーに、発行されたURLを渡してアカウントパスワードを更新してもらいます。</div>
      <div class="box11-sub-text">・アカウントのパスワードが更新完了するまで、コモンズマネジメントにログインすることは出来ません。</div>
      <div class="box11-sub-text">・アカウントのパスワードの更新が終わった後のアカウントで、再度URLを発行することは出来ません。（パスワードを忘れた場合は開発へ連絡してください）</div>
      <div class="box11-sub-text">・<span class="red--text"><b>URLの発行は実行者のログが残ります。責任を持って管理してください。</b></span></div>
    </div>

    <v-form ref="form" v-model="valid" lazy-validation>

      <div class="box11">
        <v-col class="pa-5" cols="8">
          <div v-if="roleId != 4">
            <div class="d-inline-flex">
              <v-text-field label="招待するメールアドレス" v-model="email" :rules="accountIdRules"/>
            </div>
            @pitdesign.jp
          </div>
          <div v-else>
            <v-text-field label="作成するアカウントID" placeholder="（例 : 山田太郎 =  t_yamada）" v-model="accountId" :rules="accountIdRules"/>
          </div>
          <v-radio-group v-model="roleId" row @change="operateRadioButtons">
            <v-radio label="1:管理者" :value="1" :disabled="loginUserRoleId != 1"></v-radio> <!-- 管理者のみ表示 2022/03/29 yasui -->
            <v-radio label="2:社員" :value="2"></v-radio>
            <v-radio label="3:協力会社(未実装)" :value="3" disabled></v-radio> <!-- 未実装のため非表示 2022/03/29 yasui -->
            <v-radio label="4:アルバイト(管理者のみ)" :value="4" :disabled="loginUserRoleId != 1"></v-radio>
          </v-radio-group>
          <v-btn color="warning" @click="send()" :disabled="!valid" >送信</v-btn>
        </v-col>
      </div>

    </v-form>

    <div v-if="sendFlag">
      <v-alert type="success" v-if="returnId == 1"> <!-- 招待メールの送信成功 -->
        {{alertMessage}}
      </v-alert>
      <v-alert type="success" v-if="returnId == 2"> <!-- アルバイト用URLの発行成功 -->
        URLを発行しました。有効期限は7日以内となります。<br>
        {{alertMessage}}
      </v-alert>
      <v-alert type="error" v-if="returnId == 3"> <!-- エラー -->
        {{alertMessage}}
      </v-alert>
    </div>

  </v-container>
</template>

<script>
import axios  from "axios";
export default {
  data() {
    return {
      roleId: 2,
      email: '',
      accountId:'',
      alertMessage:'',
      sendFlag: false,
      returnId: 0,
      loginUserRoleId: '',
      valid: true,
      accountIdRules: [ 
        v => !!v || '入力必須項目です。', 
        v => /^([a-z]|[a-z][a-z])_[a-z]{1,}/.test(v) || '入力が正しくありません。（例 : 山田太郎 =  t_yamada）', 
        ],
    }
  },
  methods: {
    operateRadioButtons(){
      this.email = ''
      this.accountId = ''
    },
    async send(){
      if (this.$refs.form.validate()) {
        this.$vloading.show();
        await axios.post("/api/send_regist_mail", { email: this.email, roleId: this.roleId, accountId: this.accountId, manager: JSON.parse(localStorage.user) })
        .then(res => {
          console.log(res.data)
          this.alertMessage = res.data.message
          this.returnId = res.data.return
          this.sendFlag = true
          this.$vloading.hide();
        })
        .catch((error)=>{
          console.log(error)
          this.alertMessage = "エラーが発生しました。問題を解決出来ない場合は開発に問い合わせをお願いします。"
          this.returnId = 3
          this.$vloading.hide();
        })
      } else {
        this.$refs.form.validate()
      }
    }
  },
  mounted() {
    this.loginUserRoleId = JSON.parse(localStorage.user).role_id;
  }
}
</script>
