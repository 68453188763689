









import Vue from "vue";
import * as XLSX from 'xlsx'; // xlsx ライブラリをインポート

export default Vue.extend({
  props: {
    selectObj: {
      type: Array,
      required: true,
    },
  },
  methods: {
    downloadExcel() {
      if (this.selectObj.length === 0) return;
      // ヘッダーとデータを取得
      const headers = Object.keys(this.selectObj[0]);
      const rows = this.selectObj.map(obj => Object.values(obj));
      // データをワークシート形式に変換
      const ws = XLSX.utils.aoa_to_sheet([headers, ...rows]);
      // ワークブックを作成
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
      // Excelファイルとしてダウンロード
      XLSX.writeFile(wb, 'data.xlsx');
    },
  },
});
