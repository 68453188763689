









import Vue from "vue";

export default Vue.extend({
  props: {
    selectObj: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      formattedText: '',
    };
  },
  watch: {
    selectObj: {
      immediate: true,
      handler(newVal) {
        this.formattedText = this.convertToFormattedText(newVal);
      },
    },
  },
  methods: {
    convertToFormattedText(objArray: Array<Record<string, any>>): string {
    if (objArray.length === 0) return '';
    // ヘッダー行の取得（キーを大文字に変換）
    const headers = Object.keys(objArray[0]).map(key => key.toUpperCase());
    // 値のエスケープ処理
    const escapeValue = (value: any) => {
      const stringValue = typeof value === 'string' ? value : String(value); // 文字列化
      const escapedValue = stringValue
        .replace(/\|/g, '\\|')  // パイプ記号をエスケープ
        .replace(/\n/g, '\\n'); // 改行をエスケープ (明示的に文字列 \n として扱う)
      return escapedValue.toLowerCase();
    };
    // データ行の取得
    const rows = objArray.map(obj =>
      Object.values(obj).map(value => escapeValue(value))
    );
    // 特定のフォーマットに整形
    const headerRow = `|${headers.join('|')}|h`;
    const dataRows = rows.map(row => `|${row.join('|')}|`).join('\n');
    return `${headerRow}\n${dataRows}`;
  },
    copyToClipboard() {
      // クリップボードにコピー
      navigator.clipboard.writeText(this.formattedText).then(() => {
        alert('コピーしました');
      }).catch(err => {
        console.error('Failed to copy text: ', err);
      });
    },
  },
});
