















































































































import Vue from "vue";
import axios from "axios";
import store from "/front/src/store/WebUI.ts";
interface Data {
  result:any[];
  cashVoucherFlg:any[];
  toll:any[];
  toll0:any[];
  toll1:any[];
  toll2:any[];
  toll3:any[];
  toll4:any[];
  toll5:any[];
  toll6:any[];
  toll7:any[];
  toll8:any[];
  toll9:any[];
}
export default Vue.extend({
  data(): Data {
    return {
      result:[],
      cashVoucherFlg:[],
      toll:[],
      toll0:[],
      toll1:[],
      toll2:[],
      toll3:[],
      toll4:[],
      toll5:[],
      toll6:[],
      toll7:[],
      toll8:[],
      toll9:[],
    }
  },
  watch: {
    selectServer(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.getResult();
      }
    }
  },
  mounted() {
    this.getResult()
  },
  methods: {
    async send(
      id:number,
      cashVoucherFlg:number,
      toll0:number,
      toll1:number,
      toll2:number,
      toll3:number,
      toll4:number,
      toll5:number,
      toll6:number,
      toll7:number,
      toll8:number,
      toll9:number,
    ): Promise<void> {
      if (!this.selectServer) { alert("物件を選択してください"); this.result = []; return; }
      if (!window.confirm('更新しますか？')) return
      this.$vloading.show();
      const url = `${process.env.VUE_APP_API_URL_BASE}/api/web_ui/cash_voucher_mas/${id}`;
      const formData = {
        serverId: this.selectServer,
        CASH_VOUCHER_FLG: cashVoucherFlg,
        TOLL: [
          { id:0, TOLL: toll0 },
          { id:1, TOLL: toll1 },
          { id:2, TOLL: toll2 },
          { id:3, TOLL: toll3 },
          { id:4, TOLL: toll4 },
          { id:5, TOLL: toll5 },
          { id:6, TOLL: toll6 },
          { id:7, TOLL: toll7 },
          { id:8, TOLL: toll8 },
          { id:9, TOLL: toll9 }
        ]
      };
      return await axios
      .patch(url, formData)
      .then(() => {
        alert("更新しました");
      })
      .catch((error: any) => {
        console.log(error)
        alert("更新に失敗しました");
      })
      .finally(() => {
        this.getResult()
        this.$vloading.hide();
      });
    },
    async getResult(): Promise<void> {
      if (!this.selectServer) { alert("物件を選択してください"); this.result = []; return; }
      this.$vloading.show();
      const url = `${process.env.VUE_APP_API_URL_BASE}/api/web_ui/cash_voucher_mas`;
      return await axios
      .get(url, {params: { id: this.selectServer }} )
      .then((res:any) => {
        this.result = [];
        this.cashVoucherFlg = [];
        this.toll = [];
        this.toll0 = [];
        this.toll1 = [];
        this.toll2 = [];
        this.toll3 = [];
        this.toll4 = [];
        this.toll5 = [];
        this.toll6 = [];
        this.toll7 = [];
        this.toll8 = [];
        this.toll9 = [];
        this.result = res.data.flat()
        this.result.forEach(item => {
          if (item.service_mas) {
            this.cashVoucherFlg.push(item.service_mas.CASH_VOUCHER_FLG);
          } else {
            this.cashVoucherFlg.push(null);
          };
          if (item.cash_voucher_mas) {
            this.toll0.push(item.cash_voucher_mas[0].TOLL);
            this.toll1.push(item.cash_voucher_mas[1].TOLL);
            this.toll2.push(item.cash_voucher_mas[2].TOLL);
            this.toll3.push(item.cash_voucher_mas[3].TOLL);
            this.toll4.push(item.cash_voucher_mas[4].TOLL);
            this.toll5.push(item.cash_voucher_mas[5].TOLL);
            this.toll6.push(item.cash_voucher_mas[6].TOLL);
            this.toll7.push(item.cash_voucher_mas[7].TOLL);
            this.toll8.push(item.cash_voucher_mas[8].TOLL);
            this.toll9.push(item.cash_voucher_mas[9].TOLL);
          } else {
            this.toll0.push(null);
            this.toll1.push(null);
            this.toll2.push(null);
            this.toll3.push(null);
            this.toll4.push(null);
            this.toll5.push(null);
            this.toll6.push(null);
            this.toll7.push(null);
            this.toll8.push(null);
            this.toll9.push(null);
          };
        });
      })
      .catch((error: any) => {
        console.log(error)
        alert("物件への接続に失敗しました");
        this.result = [];
      })
      .finally(() => {
        this.$vloading.hide();
      });
    },
  },
  computed: {
    selectServer() {
      return store.state.WebUI.server;
    }
  }
});
