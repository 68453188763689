var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.$route.path!='/')?_c('header',{ref:"header"},[(_vm.userRole <= 4 && _vm.name != null)?_c('v-app-bar',{attrs:{"app":"","elevation":"10","dense":"","color":"blue-grey darken-3","dark":""}},[(_vm.$route.path!='/'&& _vm.$route.path!='/user-update' && _vm.$route.path!='/invalid-link')?_c('v-app-bar-nav-icon',{attrs:{"id":"version-mobile-menu-icon"},on:{"click":function($event){$event.stopPropagation();_vm.drawer = !_vm.drawer}}}):_vm._e(),(_vm.name != null)?_c('v-menu',{attrs:{"transition":"slide-x-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(_vm.$route.path!='/'&& _vm.$route.path!='/user-update' && _vm.$route.path!='/invalid-link')?_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"id":"version-pc-menu-icon"}},'v-icon',attrs,false),on),[_vm._v("mdi-apps")]):_vm._e()]}}],null,false,1670894996)},[_c('div',{staticClass:"all-menu"},[_c('v-icon',{staticClass:"float-right mr-4",attrs:{"large":"","dark":""}},[_vm._v("mdi-close")]),_c('p',{staticClass:"text-h5 mb-1"},[_c('v-icon',{staticClass:"mb-1 mr-1",attrs:{"x-large":"","color":"orange"}},[_vm._v("mdi-apps")]),_c('span',{staticClass:"orange--text"},[_c('b',[_vm._v("MENU")])])],1),_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('p',{staticClass:"text-h6 ml-10"},[_c('router-link',{staticClass:"text-decoration-none",attrs:{"to":"/home"}},[_c('span',{class:hover ? 'orange--text' : 'white--text'},[_c('b',[_vm._v("ホームへ戻る")])])])],1)]}}],null,false,1467199741)}),_c('v-divider',{staticClass:"mr-4",attrs:{"dark":""}}),_c('v-list-item',[_c('v-list-item-content',_vm._l((_vm.groups),function(group){return _c('div',{key:group.id,attrs:{"link":"","dense":""}},[_c('v-list-item-title',{staticClass:"ma-2"},[_c('v-icon',{staticClass:"mb-1",attrs:{"left":"","color":"grey"}},[_vm._v(_vm._s(group.icon))]),_c('span',{staticClass:"grey--text"},[_vm._v(_vm._s(group.title))])],1),_vm._l((group.menus),function(menu){return _c('div',{key:menu.id,staticClass:"ml-10 my-1"},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('router-link',{staticClass:"text-decoration-none",attrs:{"to":menu.url}},[_c('span',{class:hover ? 'orange--text' : 'white--text'},[_c('b',[_vm._v(_vm._s(menu.name))])])])]}}],null,true)})],1)})],2)}),0)],1)],1)]):_vm._e(),_c('v-divider',{attrs:{"vertical":"","inset":""}}),_c('div',{staticClass:"ml-2"},[_c('router-link',{staticClass:"text-decoration-none",attrs:{"to":"/home"}},[_c('span',{staticClass:"white--text"},[_c('b',[_vm._v("COMMONS")])])])],1)],1):_vm._e(),_c('v-navigation-drawer',{attrs:{"fixed":"","temporary":""},model:{value:(_vm.drawer),callback:function ($$v) {_vm.drawer=$$v},expression:"drawer"}},[(_vm.$route.path!='/')?_c('v-list',{attrs:{"nav":"","dense":""}},[_c('p',{staticClass:"text-center"},[_vm._v("こんにちは "+_vm._s(_vm.name)+" さん")]),_vm._l((_vm.items),function(item){return _c('v-list-item',{key:item.title,attrs:{"link":""}},[_c('router-link',{staticClass:"text-decoration-none",attrs:{"to":item.url}},[_c('v-list-item-content',[_c('v-list-item-title',[_c('v-icon',{attrs:{"left":""}},[_vm._v(_vm._s(item.icon))]),_c('span',{staticClass:"black--text"},[_vm._v(_vm._s(item.title))])],1)],1)],1)],1)})],2):_vm._e()],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }