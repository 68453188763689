

















































import Vue from "vue";
import axios from "axios";
import store from "/front/src/store/WebUI.ts";
interface Data {
  result: any[];
  loading: boolean;
}
export default Vue.extend({
  data(): Data {
    return {
      result: [],
      loading: false,
    }
  },
  props:{
    alertGroupId: {}
  },
  watch: {
    selectServer(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.getAlertRecurrence(this.alertGroupId);
      }
    }
  },
  mounted() {
    this.getAlertRecurrence(this.alertGroupId);
  },
  methods: {
    // 更新実行
    async send(
      parkingId:string,
      alertCount:number,
      alertInterval:number,
    ): Promise<void> {
      if (!window.confirm('更新しますか？')) return
      const url = `${process.env.VUE_APP_API_URL_BASE}/api/web_ui/alert_recurrence/${parkingId}`;
      const formData = {
        SERVER_ID: this.selectServer,
        PARKING_ID: parkingId,
        ALERT_GROUP_ID: this.alertGroupId,
        ALERT_COUNT: alertCount,
        ALERT_INTERVAL: alertInterval
      };
      return await axios
      .patch(url, formData)
      .then(() => {
        alert("更新しました");
        this.getAlertRecurrence(this.alertGroupId);
      })
      .catch((error: any) => {
        console.log(error)
        alert("更新に失敗しました");
      });
    },
    async getAlertRecurrence(id:number): Promise<void> {
      console.log("getAlertRecurrence")
      this.result = [];
      if (this.selectServer) {
        this.loading = true;
        const url = `${process.env.VUE_APP_API_URL_BASE}/api/web_ui/alert_recurrence`;
        return await axios
        .get(url, { params: { SERVER_ID: this.selectServer, ALERT_GROUP_ID: id } })
        .then((res:any) => {
          this.result = res.data;
        })
        .catch((error: any) => {
          console.log(error)
          alert("物件への接続に失敗しました");
          this.result = [];
        })
        .finally(() => {
          this.loading = false;
        });
      }
    },
  },
  computed: {
    selectServer() {
      return store.state.WebUI.server;
    }
  }
});
