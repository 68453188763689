






























































import Vue from "vue";
import axios from "axios";
interface Params {
  result: any;
  dialog: boolean;
}
export default Vue.extend({
  data(): Params {
    return {
      result: [],
      dialog: false,
    }
  },
  methods: {
    async getSettings(): Promise<void> {
      this.$vloading.show();
      const url = `${process.env.VUE_APP_API_URL_BASE}/api/web_ui/settings`;
      const formData = { params: { id: this.selectServer } };
      return await axios
      .get(url, formData)
      .then(res => {
        console.log(res.data)
        this.result = res.data;
        this.dialog = true;
      })
      .catch(error => {
        console.log(error);
        alert("取得失敗")
      })
      .finally(() => {
        this.$vloading.hide()
      })
    },
  },
  props:{
    selectServer:{}
  }
});
