
































































































































































































import Vue from "vue";
import axios from "axios";
import store from "/front/src/store/WebUI.ts";
import VehicleInformations from "@/components/WebUI/VehicleInformations/index.vue";
import ObjectToCsv from "@/components/OutPut/ObjectToCsv.vue"
import ObjectToBacklog from "@/components/OutPut/ObjectToBacklog.vue"
import ObjectToExcel from "@/components/OutPut/ObjectToExcel.vue"
import create from "@/components/WebUI/BlackLists/create.vue";
import update from "@/components/WebUI/BlackLists/update.vue";

interface Data {
  allParkingResult:any[];
  selectParkingResult:any[];
  headers:any[];
  dialog: boolean;
  show: any[];
  successCarNumber:string;
  selectParking:number;
  createDialog:boolean;
  updateDialog:boolean;
  createLoading:boolean;
  updateLoading:boolean;
  updateObject:object;
  selected:any[];
}
export default Vue.extend({
  data(): Data {
    return {
      allParkingResult:[],
      selectParkingResult:[],
      headers:[
        { text: 'id', value: "BLACK_ID" },
        { text: 'ナンバー', value: "NUMBER" },
        { text: '種別', value: "KIND_ID" },
        { text: '登録時間', value: "REGIST_DTE" },
        { text: '', value: "UPDATE" },
        { text: '', value: "DESTROY" },
      ],
      successCarNumber:"",
      show: [],
      dialog: false,
      selectParking:0,
      createDialog:false,
      updateDialog: false,
      updateObject: {},
      selected:[],
      createLoading:false,
      updateLoading:false,
    }
  },
  watch: {
    selectServer(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.getResult();
      }
    }
  },
  mounted() {
    this.getResult()
  },
  components:{
    VehicleInformations,
    create,
    update,
    ObjectToCsv,
    ObjectToBacklog,
    ObjectToExcel
  },
  methods: {
    openCreateDialog(parking:number){
      this.selectParking = parking;
      this.createDialog = true;
    },
    updateDialogOpen(item: object) {
      if (item.MAIL_ADDRESS === null) {
        item.MAIL_ADDRESS = [];
      }
      this.updateObject = item;
      this.updateDialog = true;
    },
    async destroy(item:any): Promise<void> {
      if (!this.selectServer) { alert("物件を選択してください"); }
      if (!window.confirm('本当に削除しますか？')) return;
      this.$vloading.show();
      const url = `${process.env.VUE_APP_API_URL_BASE}/api/web_ui/black_list_mgr/${item.BLACK_ID}`;
      const formData = { params: { SERVER_ID: this.selectServer, PARKING_ID: item.PARKING_ID } };
      return await axios
      .delete(url, formData)
      .then(res => {
        if (res.data.error){ alert(res.data.message); return;};
        alert("成功しました");
        this.getResult();
      })
      .catch(error => {
        console.log(error);
        alert("失敗しました");
      })
      .finally(() => {
        this.$vloading.hide()
      });
    },
    async history(place:string, classNumber:string, kana:string, carNumber:string): Promise<void> {
      this.$vloading.show();
      const url = `${process.env.VUE_APP_API_URL_BASE}/api/vehicles_information_show`;
      const formData = { params: {
                                  server:      this.selectServer,
                                  place:       place,
                                  classNumber: classNumber,
                                  kana:        kana,
                                  carNumber:   carNumber
                        } };
      return await axios
      .get(url, formData)
      .then(res => {
        this.successCarNumber = place + ' ' + classNumber + ' ' + kana + ' ' + carNumber;
        this.show = res.data;
        this.dialog = true;
      })
      .catch(error => {
        alert(error);
      })
      .finally(() => {
        this.$vloading.hide()
      });
    },
    async getResult(): Promise<void> {
      if (!this.selectServer) { alert("物件を選択してください"); this.result = []; return; }
      this.$vloading.show();
      const url = `${process.env.VUE_APP_API_URL_BASE}/api/web_ui/black_list_mgr`;
      return await axios
      .get(url, { params: { id: this.selectServer } } )
      .then((res:any) => {
        if (res.data.error){ alert(res.data.message); return;}; 
        res.data.forEach(record => {
          if (record.MAIL_ADDRESS) {
            try {
              record.MAIL_ADDRESS = JSON.parse(record.MAIL_ADDRESS.replace(/\\"/g, ''));
            } catch (error) {
              console.error("MAIL_ADDRESSの変換エラー:", error);
            }
          }
        });
        // 結果を格納する配列
        const allParking = [];
        const selectParking = {};
        // データの振り分け処理
        res.data.forEach(item => {
          if (item.PARKING_ID === 0) {
            allParking.push(item);
          } else {
            if (!selectParking[item.PARKING_ID]) {
              selectParking[item.PARKING_ID] = [];
            }
            selectParking[item.PARKING_ID].push(item);
          }
        });
        // selectParkingを配列の配列に変換
        const selectParkingArray = Object.values(selectParking);
        this.allParkingResult = allParking;
        this.selectParkingResult = selectParkingArray;
      })
      .catch((error: any) => {
        alert(error);
        this.result = [];
      })
      .finally(() => {
        this.$vloading.hide();
      });
    },
  },
  computed: {
    selectServer() {
      return store.state.WebUI.server;
    }
  }
});
