



















import Vue from "vue";
import store from "/front/src/store/WebUI.ts";
import AlertMailAddress from "@/components/WebUI/Alerts/AlertMailAddress.vue";
import AlertInformation from "@/components/WebUI/Alerts/AlertInformation.vue";
import AlertRecurrence from "@/components/WebUI/Alerts/AlertRecurrence.vue";

interface Data {
  alertGroupId: number;
}
export default Vue.extend({
  data(): Data {
    return {
      alertGroupId: 1 // 緊急手配用
    }
  },
  components:{
    AlertInformation,
    AlertRecurrence,
    AlertMailAddress
  },
  computed: {
    selectServer() {
      return store.state.WebUI.server;
    }
  }
});
