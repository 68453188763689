
























































import Vue from "vue";
import axios from "axios";
interface Params {
  result: any;
  dialog: boolean;
  request: boolean;
}
export default Vue.extend({
  data(): Params {
    return {
      result: [],
      dialog: false,
      request: false,
    }
  },
  methods: {
    async getParkingMgr(): Promise<void> {
      this.result = [];
      this.dialog = true;
      this.request = true;
      if (!this.selectServer) { alert("物件を選択してください"); this.result = []; return; }
      const url = `${process.env.VUE_APP_API_URL_BASE}/api/health_check`;
      return await axios
      .get(url, {params: { SERVER_ID: this.selectServer }} )
      .then((res:any) => {
        this.result = res.data;
      })
      .catch((error: any) => {
        console.log(error)
        alert("ヘルスチェックに失敗しました");
        this.result = [];
      })
      .finally(() => {
        this.request = false;
      });
    },
  },
  props:{
    selectServer:{}
  }
});
