










































import Vue from "vue";
import axios from "axios";
interface Params {
  notification:any;
}
export default Vue.extend({
  data(): Params {
    return {
      notification:[]
    }
  },
  methods: {
    isNew(startDate:string) {
      const start = new Date(startDate);
      const today = new Date();
      const oneWeekLater = new Date();
      today.setHours(0, 0, 0, 0);
      oneWeekLater.setHours(0, 0, 0, 0);
      oneWeekLater.setDate(today.getDate() + 7);
      return start >= today && start <= oneWeekLater;
    },
    async getNotification(): Promise<void>{
      this.$vloading.show();
      const url = `${process.env.VUE_APP_API_URL_BASE}/api/notifications`;
      return await axios
      .get(url)
      .then(res => {
        this.notification = res.data.filter(item => item.parkings !== null);
      })
      .finally(() => {
        this.$vloading.hide();
      });
    },
  },
  mounted() {
    this.getNotification();
  }
})
