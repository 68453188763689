



















































































































import Vue from "vue";
import store from "/front/src/store/WebUI.ts";
import ObjectToCsv from "@/components/OutPut/ObjectToCsv.vue"
import ObjectToBacklog from "@/components/OutPut/ObjectToBacklog.vue"
interface Data {
  propsObject: any[];
  headers: any[];
  selectObj: any[];
  search:string;
}
export default Vue.extend({
  data(): Data {
    return {
      propsObject: [],
      headers: [
        { text: 'SEQ_NO', value: 'SEQ_NO'},
        { text: 'PARKING_ID', value: 'PARKING_ID' },
        { text: '物件名', value: 'PARKING_NAME' },
        { text: '精算機', value: 'ACCOUNT_NAME' },
        { text: '作成時間', value: 'HAPPEN_DATETIME' },
        { text: 'ログ', value: 'MESSAGE' }
      ],
      selectObj:[],
      search:"",
    }
  },
  components:{
    ObjectToCsv,
    ObjectToBacklog
  },
  methods: {
    reload(flg:boolean){
      if (!window.confirm('全期間で取得した場合、リダイレクトに時間がかかる可能性があります。\n本当に実行しますか？')) return
      this.$emit('reload', flg);
    }
  },
  props:{
    errorLog: {},
    loading: {},
  },
  watch: {
    errorLog: {
      handler(newVal) {
        this.propsObject = newVal; // 新しい値を反映
        this.selectObj = [];
      },
      deep: true, // オブジェクトの深い変更を監視
      immediate: true // 初回レンダリング時にも監視
    }
  },
  computed: {
    selectServer() {
      return store.state.WebUI.server;
    },
  },
});
