












































































































































































import Vue from "vue";
import axios from "axios";
import store from "/front/src/store/WebUI.ts";
interface Data {
  result:any[];
  dayOfWeek:any[];
  dayOfWeekItems:any[];
  holidayStartSeason1:any[];
  holidayEndSeason1:any[];
  holidayStartSeason2:any[];
  holidayEndSeason2:any[];
  holidayStartSeason3:any[];
  holidayEndSeason3:any[];
  holidayStartSeason4:any[];
  holidayEndSeason4:any[];
  holidayStartSeason5:any[];
  holidayEndSeason5:any[];
  parkingName01:any[];
  parkingName02:any[];
  parkingName03:any[];
  month:any[];
  day:any[];
}
export default Vue.extend({
  data(): Data {
    return {
      result:[],
      dayOfWeek:[],
      dayOfWeekItems:[
        { text: "月曜日", value: "HOLIDAY_MONDAY" },
        { text: "火曜日", value: "HOLIDAY_TUESDAY" },
        { text: "水曜日", value: "HOLIDAY_WEDNDSDAY" },
        { text: "木曜日", value: "HOLIDAY_THURSDAY" },
        { text: "金曜日", value: "HOLIDAY_FRIDAY" },
        { text: "土曜日", value: "HOLIDAY_SATURDAY" },
        { text: "日曜日", value: "HOLIDAY_SUNDAY" },
      ],
      month:[
        { text: "なし", value: null },
        { text: "1月", value: 1 },
        { text: "2月", value: 2 },
        { text: "3月", value: 3 },
        { text: "4月", value: 4 },
        { text: "5月", value: 5 },
        { text: "6月", value: 6 },
        { text: "7月", value: 7 },
        { text: "8月", value: 8 },
        { text: "9月", value: 9 },
        { text: "10月", value: 10 },
        { text: "11月", value: 11 },
        { text: "12月", value: 12 }
      ],
      day: [
        { text: "なし", value: null },
        { text: "1日", value: 1 },
        { text: "2日", value: 2 },
        { text: "3日", value: 3 },
        { text: "4日", value: 4 },
        { text: "5日", value: 5 },
        { text: "6日", value: 6 },
        { text: "7日", value: 7 },
        { text: "8日", value: 8 },
        { text: "9日", value: 9 },
        { text: "10日", value: 10 },
        { text: "11日", value: 11 },
        { text: "12日", value: 12 },
        { text: "13日", value: 13 },
        { text: "14日", value: 14 },
        { text: "15日", value: 15 },
        { text: "16日", value: 16 },
        { text: "17日", value: 17 },
        { text: "18日", value: 18 },
        { text: "19日", value: 19 },
        { text: "20日", value: 20 },
        { text: "21日", value: 21 },
        { text: "22日", value: 22 },
        { text: "23日", value: 23 },
        { text: "24日", value: 24 },
        { text: "25日", value: 25 },
        { text: "26日", value: 26 },
        { text: "27日", value: 27 },
        { text: "28日", value: 28 },
        { text: "29日", value: 29 },
        { text: "30日", value: 30 },
        { text: "31日", value: 31 }
      ],
      holidayStartSeason1:[],
      holidayEndSeason1:[],
      holidayStartSeason2:[],
      holidayEndSeason2:[],
      holidayStartSeason3:[],
      holidayEndSeason3:[],
      holidayStartSeason4:[],
      holidayEndSeason4:[],
      holidayStartSeason5:[],
      holidayEndSeason5:[],
      parkingName01:[],
      parkingName02:[],
      parkingName03:[],
    }
  },
  watch: {
    selectServer(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.getResult();
      }
    }
  },
  mounted() {
    this.getResult()
  },
  methods: {
    createSeason(value:any[]) {
      if (value[0] === null || value[1] === null) {
        return null ;
      }
      return `2000-${String(value[0]).padStart(2, '0')}-${String(value[1]).padStart(2, '0')} 00:00:00`;
    },
    async send(
      id:number,
      dayOfWeek:any[],
      holidayStartSeason1:any[],
      holidayEndSeason1:any[],
      holidayStartSeason2:any[],
      holidayEndSeason2:any[],
      holidayStartSeason3:any[],
      holidayEndSeason3:any[],
      holidayStartSeason4:any[],
      holidayEndSeason4:any[],
      holidayStartSeason5:any[],
      holidayEndSeason5:any[]
    ): Promise<void> {
      if (!this.selectServer) { alert("物件を選択してください"); this.result = []; return; }
      if (!window.confirm('更新しますか？')) return
      this.$vloading.show();
      const url = `${process.env.VUE_APP_API_URL_BASE}/api/web_ui/calendar_mas/${id}`;
      const formData = {
        serverId: this.selectServer,
        HOLIDAY_MONDAY: dayOfWeek.includes("HOLIDAY_MONDAY") ? 1 : 0,
        HOLIDAY_TUESDAY: dayOfWeek.includes("HOLIDAY_TUESDAY") ? 1 : 0,
        HOLIDAY_WEDNDSDAY: dayOfWeek.includes("HOLIDAY_WEDNDSDAY") ? 1 : 0,
        HOLIDAY_THURSDAY: dayOfWeek.includes("HOLIDAY_THURSDAY") ? 1 : 0,
        HOLIDAY_FRIDAY: dayOfWeek.includes("HOLIDAY_FRIDAY") ? 1 : 0,
        HOLIDAY_SATURDAY: dayOfWeek.includes("HOLIDAY_SATURDAY") ? 1 : 0,
        HOLIDAY_SUNDAY: dayOfWeek.includes("HOLIDAY_SUNDAY") ? 1 : 0,
        HOLIDAY_START_SEASON_1: this.createSeason(holidayStartSeason1),
        HOLIDAY_END_SEASON_1: this.createSeason(holidayEndSeason1),
        HOLIDAY_START_SEASON_2: this.createSeason(holidayStartSeason2),
        HOLIDAY_END_SEASON_2: this.createSeason(holidayEndSeason2),
        HOLIDAY_START_SEASON_3: this.createSeason(holidayStartSeason3),
        HOLIDAY_END_SEASON_3: this.createSeason(holidayEndSeason3),
        HOLIDAY_START_SEASON_4: this.createSeason(holidayStartSeason4),
        HOLIDAY_END_SEASON_4: this.createSeason(holidayEndSeason4),
        HOLIDAY_START_SEASON_5: this.createSeason(holidayStartSeason5),
        HOLIDAY_END_SEASON_5: this.createSeason(holidayEndSeason5)
      };
      return await axios
      .patch(url, formData)
      .then(() => {
        window.scroll({
          top: 0
        });
        alert("更新しました");
      })
      .catch((error: any) => {
        console.log(error)
        alert("更新に失敗しました");
      })
      .finally(() => {
        this.getResult();
        this.$vloading.hide();
      });
    },
    async getResult(): Promise<void> {
      if (!this.selectServer) { alert("物件を選択してください"); this.result = []; return; }
      this.$vloading.show();
      const url = `${process.env.VUE_APP_API_URL_BASE}/api/web_ui/calendar_mas`;
      return await axios
      .get(url, {params: { id: this.selectServer }} )
      .then((res:any) => {
        this.result = [];
        this.dayOfWeek = [];
        this.parkingName01 = [];
        this.parkingName02 = [];
        this.parkingName03 = [];
        this.holidayStartSeason1 = [];
        this.holidayEndSeason1 = [];
        this.holidayStartSeason2 = [];
        this.holidayEndSeason2 = [];
        this.holidayStartSeason3 = [];
        this.holidayEndSeason3 = [];
        this.holidayStartSeason4 = [];
        this.holidayEndSeason4 = [];
        this.holidayStartSeason5 = [];
        this.holidayEndSeason5 = [];
        this.result = res.data.flat()
        this.result.forEach(item => {
          // 物件名の取得
          this.parkingName01.push(item.PARKING_NAME_1);
          this.parkingName02.push(item.PARKING_NAME_2);
          this.parkingName03.push(item.PARKING_NAME_3);
          // 曜日休日設定の取得
          const days = ["MONDAY", "TUESDAY", "WEDNDSDAY", "THURSDAY", "FRIDAY", "SATURDAY", "SUNDAY"];
          const array = [];
          days.forEach(day => {
            if (item[`HOLIDAY_${day}`] == 1) {
              array.push(`HOLIDAY_${day}`);
            }
          });
          this.dayOfWeek.push(array);
          // 期間休日設定
          this.holidayStartSeason1.push(this.formatDate(item.HOLIDAY_START_SEASON_1));
          this.holidayEndSeason1.push(this.formatDate(item.HOLIDAY_END_SEASON_1));
          this.holidayStartSeason2.push(this.formatDate(item.HOLIDAY_START_SEASON_2));
          this.holidayEndSeason2.push(this.formatDate(item.HOLIDAY_END_SEASON_2));
          this.holidayStartSeason3.push(this.formatDate(item.HOLIDAY_START_SEASON_3));
          this.holidayEndSeason3.push(this.formatDate(item.HOLIDAY_END_SEASON_3));
          this.holidayStartSeason4.push(this.formatDate(item.HOLIDAY_START_SEASON_4));
          this.holidayEndSeason4.push(this.formatDate(item.HOLIDAY_END_SEASON_4));
          this.holidayStartSeason5.push(this.formatDate(item.HOLIDAY_START_SEASON_5));
          this.holidayEndSeason5.push(this.formatDate(item.HOLIDAY_END_SEASON_5));
        });
      })
      .catch((error: any) => {
        console.log(error)
        alert("物件への接続に失敗しました");
        this.result = [];
      })
      .finally(() => {
        this.$vloading.hide();
      });
    },
    formatDate(input:string) {
      if (!input) {
        return [null, null];
      }
      const date = new Date(input);
      const month = date.getMonth() + 1;
      const day = date.getDate();
      return [month, day];
    },
  },
  computed: {
    selectServer() {
      return store.state.WebUI.server;
    }
  }
});
