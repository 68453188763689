<template>
  <header ref="header" v-if="$route.path!='/'">
    <!-- app-bar -->
    <v-app-bar app elevation="10" dense v-if="userRole <= 4 && name != null" color="blue-grey darken-3" dark>
      <!-- MENUアイコン（Mobile版で表示されるサイドメニュー表示アイコン）-->
      <v-app-bar-nav-icon
        id="version-mobile-menu-icon"
        @click.stop="drawer = !drawer"
        v-if="$route.path!='/'&& $route.path!='/user-update' && $route.path!='/invalid-link'"
      />
      <!-- MENUアイコン（PC版で表示されるALLメニュー表示アイコン）-->
      <v-menu transition="slide-x-transition" v-if="name != null">
        <!-- MENUアイコン -->
        <template v-slot:activator="{ on, attrs }">
          <v-icon
            id="version-pc-menu-icon"
            class="mr-2"
            v-bind="attrs"
            v-on="on"
            v-if="$route.path!='/'&& $route.path!='/user-update' && $route.path!='/invalid-link'"
          >mdi-apps</v-icon>
        </template>
        <!-- PC版 メニュービュー -->
        <div class="all-menu">
          <!-- PC版 メニュータイトル -->
          <v-icon class="float-right mr-4" large dark>mdi-close</v-icon>
          <p class="text-h5 mb-1">
            <v-icon x-large color="orange" class="mb-1 mr-1">mdi-apps</v-icon>
            <span class="orange--text"><b>MENU</b></span>
          </p>
          <!-- PC版 ホームへ戻る -->
          <v-hover v-slot="{ hover }">
            <p class="text-h6 ml-10">
              <router-link class="text-decoration-none" to="/home">
                <span :class="hover ? 'orange--text' : 'white--text'"><b>ホームへ戻る</b></span>
              </router-link>
            </p>
          </v-hover>
          <v-divider dark class="mr-4"/>
          <!-- PC版 メニューリスト -->
          <v-list-item>
            <v-list-item-content>
              <div v-for="group in groups" :key="group.id" link dense>
                <v-list-item-title class="ma-2">
                  <v-icon left color="grey" class="mb-1">{{group.icon}}</v-icon><span class="grey--text">{{group.title}}</span>
                </v-list-item-title>
                <div class="ml-10 my-1" v-for="menu in group.menus" :key="menu.id">
                  <v-hover v-slot="{ hover }">
                    <router-link class="text-decoration-none" :to="menu.url">
                      <span :class="hover ? 'orange--text' : 'white--text'"><b>{{menu.name}}</b></span>
                    </router-link>
                  </v-hover>
                </div>
              </div>
            </v-list-item-content>
          </v-list-item>
        </div>
      </v-menu>
      <v-divider vertical inset></v-divider>
      <!-- ヘッダータイトル -->
      <div class="ml-2">
        <router-link class="text-decoration-none" to="/home">
          <span class="white--text">
            <b>COMMONS</b>
          </span>
        </router-link>
      </div>
    </v-app-bar>
    <!-- Mobile版 メニュービュー -->
    <v-navigation-drawer v-model="drawer" fixed temporary>
      <v-list v-if="$route.path!='/'" nav dense >
        <p class="text-center">こんにちは {{ name }} さん</p>
        <v-list-item v-for="item in items" :key="item.title" link>
          <router-link v-bind:to="item.url" class="text-decoration-none">
            <v-list-item-content>
              <v-list-item-title>
                <v-icon left>{{ item.icon }}</v-icon>
                <span class="black--text">{{ item.title }}</span>
              </v-list-item-title>
            </v-list-item-content>
          </router-link>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
  </header>
</template>
<script type="ts">
import Vue from "vue";
export default Vue.extend({
  data: () => ({
    drawer: false, // Mobile版サイドメニュー切り替えスイッチ
    userRole: Number, // ユーザー:role_id
    name: "", // ユーザー:name
    items: Array, // Mobile版メニュー
    groups: Array, // PC版メニュー
  }),
  mounted() {
    this.mountUser(); // ユーザー情報の取得
    this.setItems(); // Mobile版 表示メニューの取得
    this.setGroups(); // PC版 表示メニューの取得
  },
  methods:{
    // ユーザー情報の取得
    mountUser() {
      const user = localStorage.user;
      if (user !== undefined){
        this.userRole = JSON.parse(user).role_id
        this.name = JSON.parse(user).name
      }  else if (user === undefined && this.$route.path!='/' && this.$route.name!= 'user-update') {
        location.href = `${process.env.VUE_APP_FRONT_BASE_PATH}/`;
      }
    },
    setGroups() { // PC版メニューの取得
      // admin
      if (this.userRole === 0){
        this.groups = [
          {
            title: "物件情報", icon: "mdi-server", color: "#deb887",
            menus: [
              { name: "サーバー情報", url: "/servers" }
            ]
          },
          {
            title: "SQL実行", icon: "mdi-database", color: "#4682b4",
            menus: [
              { name: "Querier", url: "/query-tools" },
              { name: "UpDataCheck SQL", url: "/update-check-sql" }
            ]
          },
          {
            title: "一括登録処理", icon: "mdi-car-multiple", color: "#a52a2a",
            menus: [
              { name: "アラート通知車両登録", url: "/alert-vehicle-bulk-registration" },
            ]
          },
          {
            title: "資料出力", icon: "mdi-download-multiple", color: "#a52a2a",
            menus: [
              { name: "精算情報出力", url: "/checkout-records" },
              { name: "店舗別報告資料出力", url: "/unpaid-form" },
              { name: "未精算レコード出力", url: "/unpaid-records" },
              { name: "未精算レコード出力(入庫中)", url: "/unpaid-records-staying" },
              { name: "不正利用車両の抽出", url: "/output-unauthorized-use-vehicles" },
              { name: "未精算出庫車両抽出", url: "/unfair-vehicles" },
              { name: "満空表出力", url: "/parking-availability-reports" }
            ]
          },
          {
            title: "データサイエンス", icon: "mdi-data-matrix", color: "#888",
            menus: [
              { name: "分析グループの作成", url: "/groups" },
              { name: "売り上げ予測", url: "/sales-forecast" },
            ]
          },
          {
            title: "Backlog関連", icon: "mdi-alpha-b-box", color: "#32cd32",
            menus: [
              { name: "Backlog集計(SMP_CALL)", url: "/backlog-aggregate" }
            ]
          },
          {
            title: "Commons調査", icon: "mdi-view-dashboard", color: "#9370db",
            menus: [
              { name: "ナンバーなし調査", url: "/not-number" },
              { name: "選択式SQL調査", url: "/sql_select" },
            ]
          },
          {
            title: "Local接続", icon: "mdi-lock", color: "#ff8c00",
            menus: [
              { name: "UpDataCheck 前日", url: "/update-check-the-day-before" },
              { name: "UpDataCheck 当日", url: "/update-check-on-the-day" },
            ]
          },
          {
            title: "情報検索", icon: "mdi-magnify", color: "#b22222",
            menus: [
              { name: "未払い車両検索", url: "/unpaid-research" },
              { name: "アラート通知車両検索", url: "/black-list-research" },
              { name: "車両情報", url: "/vehicles-information" },
              { name: "？あり車両一覧", url: "/miss-recognized" },
              { name: "長期駐車車両一覧", url: "/long-term-parking-vehicle" },
            ]
          },
          {
            title: "公開API", icon: "mdi-api", color: "red",
            menus: [
              { name: "特定車両入出庫情報出力", url: "/select-in-out-information" },
              { name: "アラート車両登録(全件)", url: "/all-black-lists" },
              { name: "アラート車両削除(全件)", url: "/all-black-lists-delete" },
              { name: "アラート車両登録(選択)", url: "/select-black-lists" },
              { name: "アラート車両削除(選択)", url: "/select-black-lists-delete" },
            ]
          },
          {
            title: "メンバー招待", icon: "mdi-account", color: "#888888",
            menus: [
              { name: "ユーザー新規登録", url: "/sign-up" },
              { name: "ユーザー管理", url: "/user-list" },
            ]
          }
        ]

      // 管理者
      } else if (this.userRole === 1){
        this.groups = [
          {
            title: "物件情報", icon: "mdi-server", color: "#deb887",
            menus: [
              { name: "サーバー情報", url: "/servers" }
            ]
          },
          {
            title: "SQL実行", icon: "mdi-database", color: "#4682b4",
            menus: [
              { name: "Querier", url: "/query-tools" },
              { name: "UpDataCheck SQL", url: "/update-check-sql" }
            ]
          },
          {
            title: "一括登録処理", icon: "mdi-car-multiple", color: "#a52a2a",
            menus: [
              { name: "アラート通知車両登録", url: "/alert-vehicle-bulk-registration" },
            ]
          },
          {
            title: "資料出力", icon: "mdi-download-multiple", color: "#a52a2a",
            menus: [
              { name: "精算情報出力", url: "/checkout-records" },
              { name: "店舗別報告資料出力", url: "/unpaid-form" },
              { name: "未精算レコード出力", url: "/unpaid-records" },
              { name: "未精算レコード出力(入庫中)", url: "/unpaid-records-staying" },
              { name: "不正利用車両の抽出", url: "/output-unauthorized-use-vehicles" },
              { name: "未精算出庫車両抽出", url: "/unfair-vehicles" },
              { name: "満空表出力", url: "/parking-availability-reports" }
            ]
          },
          {
            title: "データサイエンス", icon: "mdi-data-matrix", color: "#888",
            menus: [
              { name: "分析グループの作成", url: "/groups" },
              { name: "売り上げ予測", url: "/sales-forecast" },
            ]
          },
          {
            title: "Backlog関連", icon: "mdi-alpha-b-box", color: "#32cd32",
            menus: [
              { name: "Backlog集計(SMP_CALL)", url: "/backlog-aggregate" }
            ]
          },
          {
            title: "Commons調査", icon: "mdi-view-dashboard", color: "#9370db",
            menus: [
              { name: "ナンバーなし調査", url: "/not-number" },
              { name: "選択式SQL調査", url: "/sql_select" },
            ]
          },
          {
            title: "Local接続", icon: "mdi-lock", color: "#ff8c00",
            menus: [
              { name: "UpDataCheck 前日", url: "/update-check-the-day-before" },
              { name: "UpDataCheck 当日", url: "/update-check-on-the-day" },
            ]
          },
          {
            title: "情報検索", icon: "mdi-magnify", color: "#b22222",
            menus: [
              { name: "未払い車両検索", url: "/unpaid-research" },
              { name: "アラート通知車両検索", url: "/black-list-research" },
              { name: "車両情報", url: "/vehicles-information" },
              { name: "？あり車両一覧", url: "/miss-recognized" },
              { name: "長期駐車車両一覧", url: "/long-term-parking-vehicle" },
            ]
          },
          {
            title: "メンバー招待", icon: "mdi-account", color: "#888888",
            menus: [
              { name: "ユーザー新規登録", url: "/sign-up" },
              { name: "ユーザー管理", url: "/user-list" },
            ]
          }
        ]

      // 社員
      } else if (this.userRole === 2){ // 社員
        this.groups = [
          {
            title: "物件情報", icon: "mdi-server", color: "#deb887",
            menus: [
              { name: "サーバー情報", url: "/servers" }
            ]
          },
          {
            title: "一括登録処理", icon: "mdi-car-multiple", color: "#a52a2a",
            menus: [
              { name: "アラート通知車両登録", url: "/alert-vehicle-bulk-registration" },
            ]
          },
          {
            title: "資料出力", icon: "mdi-download-multiple", color: "#a52a2a",
            menus: [
              { name: "精算情報出力", url: "/checkout-records" },
              { name: "店舗別報告資料出力", url: "/unpaid-form" },
              { name: "未精算レコード出力", url: "/unpaid-records" },
              { name: "未精算レコード出力(入庫中)", url: "/unpaid-records-staying" },
              { name: "不正利用車両の抽出", url: "/output-unauthorized-use-vehicles" },
              { name: "未精算出庫車両抽出", url: "/unfair-vehicles" },
              { name: "満空表出力", url: "/parking-availability-reports" }
            ]
          },
          {
            title: "データサイエンス", icon: "mdi-data-matrix", color: "#888",
            menus: [
              { name: "分析グループの作成", url: "/groups" },
              { name: "売り上げ予測", url: "/sales-forecast" },
            ]
          },
          {
            title: "Backlog関連", icon: "mdi-alpha-b-box", color: "#32cd32",
            menus: [
              { name: "Backlog集計(SMP_CALL)", url: "/backlog-aggregate" }
            ]
          },
          {
            title: "Commons調査", icon: "mdi-view-dashboard", color: "#9370db",
            menus: [
              { name: "ナンバーなし調査", url: "/not-number" },
              { name: "選択式SQL調査", url: "/sql_select" },
            ]
          },
          {
            title: "情報検索", icon: "mdi-magnify", color: "#b22222",
            menus: [
              { name: "未払い車両検索", url: "/unpaid-research" },
              { name: "アラート通知車両検索", url: "/black-list-research" },
              { name: "車両情報", url: "/vehicles-information" },
              { name: "？あり車両一覧", url: "/miss-recognized" },
              { name: "長期駐車車両一覧", url: "/long-term-parking-vehicle" },
            ]
          },
          {
            title: "メンバー招待", icon: "mdi-account", color: "#888888",
            menus: [
              { name: "ユーザー新規登録", url: "/sign-up" }
            ]
          }
        ]

      // 協力会社
      } else if (this.userRole === 3){
        this.groups = []

      // アルバイト
      } else if (this.userRole === 4 && this.name != null){
        this.groups = [
          {
            title: "Commons調査", icon: "mdi-view-dashboard", color: "#9370db",
            menus: [
              { name: "ナンバーなし調査", url: "/not-number" },
              { name: "選択式SQL調査", url: "/sql_select" },
            ]
          },
          {
            title: "情報検索", icon: "mdi-magnify", color: "#b22222",
            menus: [
              { name: "車両情報", url: "/vehicles-information" }
            ]
          },
        ]

      }
    },
    // Mobile版メニューの取得(Mobile対応できていないメニューがほとんどなので、対応できるまでは表示するべきではないと思う yasuiメモ)
    setItems() {
      if (this.userRole === 0){ // admin
        this.items = [
          { title: "サーバ情報", icon: "mdi-view-dashboard", url: "/servers" },
          { title: "Querier", icon: "mdi-database-search", url: "/query-tools" },
          { title: "アラート車両一括登録", icon: "mdi-gavel", url: "/alert-vehicles" },
          { title: "精算情報出力", icon: "mdi-cash-usd-outline", url: "/checkout-records" },
          { title: "未精算レコード出力", icon: "mdi-currency-usd", url: "/unpaid-records"  },
          { title: "未精算レコード出力(入庫中)", icon: "mdi-currency-usd", url: "/unpaid-records-staying" },
          { title: "未払い車両検索", icon: "mdi-car-traction-control", url: "/unpaid-research" },
          { title: "ログアウト", icon: "mdi-logout", url: "/" },
        ]
      } else if (this.userRole === 1){ // 管理者
        this.items = [
          { title: "サーバ情報", icon: "mdi-view-dashboard", url: "/servers" },
          { title: "Querier", icon: "mdi-database-search", url: "/query-tools" },
          { title: "アラート車両一括登録", icon: "mdi-gavel", url: "/alert-vehicles" },
          { title: "精算情報出力", icon: "mdi-cash-usd-outline", url: "/checkout-records" },
          { title: "未精算レコード出力", icon: "mdi-currency-usd", url: "/unpaid-records"  },
          { title: "未精算レコード出力(入庫中)", icon: "mdi-currency-usd", url: "/unpaid-records-staying" },
          { title: "未払い車両検索", icon: "mdi-car-traction-control", url: "/unpaid-research" },
          { title: "ログアウト", icon: "mdi-logout", url: "/" },
        ]
      } else if (this.userRole === 2){ // 社員
        this.items = [
          { title: "サーバ情報", icon: "mdi-view-dashboard", url: "/servers" },
          { title: "アラート車両一括登録", icon: "mdi-gavel", url: "/alert-vehicles" },
          { title: "精算情報出力", icon: "mdi-cash-usd-outline", url: "/checkout-records" },
          { title: "未精算レコード出力", icon: "mdi-currency-usd", url: "/unpaid-records"  },
          { title: "未精算レコード出力(入庫中)", icon: "mdi-currency-usd", url: "/unpaid-records-staying" },
          { title: "未払い車両検索", icon: "mdi-car-traction-control", url: "/unpaid-research" },
          { title: "ログアウト", icon: "mdi-logout", url: "/" },
        ]
      } else if (this.userRole === 3){ // 協力会社
        this.items = [
          { title: "ログアウト", icon: "mdi-logout", url: "/" },
        ]
      } else if (this.userRole === 4){ // アルバイト
        this.items = [
          { title: "ログアウト", icon: "mdi-logout", url: "/" },
        ]
      }
    },
  }
})
</script>
<style scoped>
  /* PC版の全メニュー表示枠のデザイン */
  .all-menu {
    width:400px;
    height:120vh;
    padding:20px 10px 10px 30px;
    background:#2A3B4D;
    zoom: 80%;
  }
  /* PC版の全メニュー表示アイコンの非表示条件 */
  @media screen and (max-width: 959px) {
    #version-pc-menu-icon {
      display :none;
    }
  }
  /* モバイル版のサイドメニュー表示アイコンの非表示条件*/
  @media screen and (min-width: 960px) {
    #version-mobile-menu-icon {
      display :none;
    }
  }
</style>
