





















































































































import Vue from "vue";
import axios from "axios";
import UsageHistoryList from "@/components/WebUI/VehicleInformations/UsageHistoryList.vue";
import DeleteHistoryList from "@/components/WebUI/VehicleInformations/DeleteHistoryList.vue";
import UnpaidList from "@/components/WebUI/VehicleInformations/UnpaidList.vue";
import PaidList from "@/components/WebUI/VehicleInformations/PaidList.vue";
import ReadCommon from "@/components/WebUI/VehicleInformations/ReadCommon.vue";

export default Vue.extend({
  components: {
    UsageHistoryList,
    DeleteHistoryList,
    UnpaidList,
    PaidList,
    ReadCommon
  },
  props: {
    result: {
      type: Object,
      required: true,
    },
    successCarNumber: {
      type: String,
      required: true,
    },
    selectServer: {
      type: String,
      required: false,
    }
  },
  data() {
    return {
      copied: false,  // 成功時のフラグ
      error: false,   // 失敗時のフラグ
    };
  },
  watch: {
    // 車両のナンバーを監視
    successCarNumber(newVal, oldVal) {
      // 車両が切り替わった際にフラグをリセット
      this.copied = false;
      this.error = false;
    }
  },
  methods: {
    async generateUrl() {
      try {
        // フラグをリセット
        this.copied = false;
        this.error = false;

        const [regionName, classNumber, kana, vehicleNumber] = this.successCarNumber.split(' ');

        // search_vehicle API を呼び出して vehicle_id を取得する
        const searchUrl = `${process.env.VUE_APP_API_URL_BASE}/api/unpaid_balance_settlement_app_client/search_vehicle`;
        const searchResponse = await axios.post(searchUrl, {
          regionName,    // 地域名
          classNumber,   // クラスナンバー
          kana,          // カナ
          vehicleNumber  // 車両番号
        });

        const vehicleId = searchResponse.data.vehicle_id;

        if (!vehicleId) {
          throw new Error("Vehicle ID not found in the response.");
        }

        // JWT生成APIを呼び出して、vehicle_id と vehicle_number を送信
        const jwtUrl = `${process.env.VUE_APP_API_URL_BASE}/api/unpaid_balance_settlement_app_client/generate_jwt`;
        const jwtResponse = await axios.post(jwtUrl, {
          vehicleId: vehicleId,        // 取得した vehicle_id を送信
          vehicleNumber: this.successCarNumber  // vehicle_numberも送信
        });

        const jwt = jwtResponse.data.jwt;

        // URLを生成してクリップボードにコピー
        const copyUrl = `${process.env.VUE_APP_PAST_PARKING_FEES_APP_FRONT_URL_BASE}/total-amount?token=${jwt}`;
        await navigator.clipboard.writeText(copyUrl);

        // コピー成功時のフラグを設定
        this.copied = true;

      } catch (error) {
        console.error("URL生成中にエラーが発生しました", error);

        // エラー発生時のフラグを設定
        this.error = true;
      }
    },
    reload() {
      const number = this.successCarNumber.split(" ");
      this.$emit('reload', number[0], number[1], number[2], number[3]);
    },
    close() {
      const number = this.successCarNumber.split(" ");
      this.$emit('close');
    }
  }
});
