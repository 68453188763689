


























































































































import Vue from "vue";
import axios from "axios";
import store from "/front/src/store/WebUI.ts";
interface Data {
  cameraObject: any[];
  updateDialog: boolean;
  gateObject: any[];
  gateSelectObject: any[];
  parkingId:number | null;
  cameraId:number | null;
  cameraName:string | null;
  inOutKind:number | null;
  recognizeFolderPath:string | null;
  cameraFolderName:string | null;
  entRantId:number | null;
  valid: boolean;
}
export default Vue.extend({
  data(): Data {
    return {
      cameraObject: [],
      updateDialog: false,
      gateObject: [],
      gateSelectObject: [],
      parkingId:null,
      cameraId:null,
      cameraName:null,
      inOutKind:null,
      recognizeFolderPath:null,
      cameraFolderName:null,
      entRantId:null,
      valid: false,
    }
  },
  // 受け取ったオブジェクトの更新
  watch: {
    cameraConfig: {
      handler(newVal) {
        this.cameraObject = newVal;
      },
      deep: true,
      immediate: true
    },
    exitEntranceInfo: {
      handler(newVal) {
        this.gateObject = newVal;
      },
      deep: true,
      immediate: true
    },
  },
  methods: {
    // 更新モーダルオープン
    openUpdateDialog(value:object, id:number){
      // 各種パラメータの受け取り
      this.parkingId = id;
      this.cameraId = value.CAMERA_ID;
      this.cameraName = value.CAMERA_NAME;
      this.inOutKind = value.INOUT_KIND;
      this.recognizeFolderPath = value.RECOGNIZE_FOLDER_PATH;
      this.cameraFolderName = value.CAMERA_FOLDER_NAME;
      this.entRantId = value.ENTRANT_ID;
      // 出入り口名の受け取り
      this.gateSelectObject = [];
      this.gateObject.forEach(item => {
        if (!item.error){
          item.forEach(val => {
            if (val.PARKING_ID == id) {
              this.gateSelectObject.push(val)
            }
          })
        }
      });
      // モーダルオープン
      this.updateDialog = true;
    },
    // 更新実行
    async send(
      id:number,
      cameraId:number,
      cameraName:string,
      inOutKind:number,
      recognizeFolderPath:string,
      cameraFolderName:string,
      entRantId:number
    ): Promise<void> {
      if (!window.confirm('更新しますか？')) return
      this.$vloading.show();
      const url = `${process.env.VUE_APP_API_URL_BASE}/api/web_ui/recognizer_camera_settings/${id}`;
      const formData = {
        serverId: this.selectServer,
        id: id,
        CAMERA_ID: cameraId,
        CAMERA_NAME: cameraName,
        INOUT_KIND: inOutKind,
        RECOGNIZE_FOLDER_PATH: recognizeFolderPath,
        CAMERA_FOLDER_NAME: cameraFolderName,
        ENTRANT_ID: entRantId
      };
      return await axios
      .patch(url, formData)
      .then(() => {
        alert("更新しました");
        this.updateDialog = false;
        this.$emit("reload");
      })
      .catch((error: any) => {
        console.log(error)
        alert("更新に失敗しました");
      })
      .finally(() => {
        this.$vloading.hide();
      });
    },
  },
  props:{
    cameraConfig:{}, // tbl_recognizer_camera_settings
    loading:{},
    exitEntranceInfo:{}, // tbl_exit_entrance_info
  },
  computed: {
    selectServer() {
      return store.state.WebUI.server; // 選択中の物件
    }
  }
});
