









































































































































































































































































































































































import Vue from "vue";
import axios from "axios";
import store from "/front/src/store/WebUI.ts";
interface Data {
  result: any[];
  parkingName01: any[];
  parkingName02: any[];
  parkingName03: any[];
  parkingName04: any[];
  parkingName05: any[];
  parkingName06: any[];
  tel: any[];
  address: any[];
  id4: any[];
  ipAddress: any[];
  mysqlPort: any[];
  socketPort: any[];
  parkingNumber: any[];
  emptyApprovalTime: any[];
  supportCenterCall: any[];
  alertSignalTime: any[];
  exitSignalTime: any[];
  waitEntrantTime: any[];
  waitExitTime: any[];
  serviceEntrantTime: any[];
  holidayServiceEntrantTime: any[];
  reChargeTime: any[];
  waitUnpaidTime: any[];
  qrShopId: any[];
  useHolidayCalenderFlg: any[];
}
export default Vue.extend({
  data(): Data {
    return {
      result:[],
      parkingName01:[],
      parkingName02:[],
      parkingName03:[],
      parkingName04:[],
      parkingName05:[],
      parkingName06:[],
      tel:[],
      address:[],
      id4:[],
      ipAddress:[],
      mysqlPort:[],
      socketPort:[],
      parkingNumber:[],
      emptyApprovalTime:[],
      supportCenterCall:[],
      alertSignalTime:[],
      exitSignalTime:[],
      waitEntrantTime:[],
      waitExitTime:[],
      serviceEntrantTime:[],
      holidayServiceEntrantTime:[],
      reChargeTime:[],
      waitUnpaidTime:[],
      qrShopId:[],
      useHolidayCalenderFlg:[],
    }
  },
  watch: {
    selectServer(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.getParkingMgr();
      }
    }
  },
  mounted() {
    this.getParkingMgr()
  },
  methods: {
    async send(
      id:number,
      parkingName01:string,
      parkingName02:string,
      parkingName03:string,
      parkingName04:string,
      parkingName05:string,
      parkingName06:string,
      tel:string,
      address:string,
      id4:number,
      ipAddress:string,
      mysqlPort:number,
      socketPort:number,
      parkingNumber:number,
      emptyApprovalTime:number,
      supportCenterCall:string,
      alertSignalTime:number,
      exitSignalTime:number,
      waitEntrantTime:number,
      waitExitTime:number,
      serviceEntrantTime:number,
      holidayServiceEntrantTime:number,
      reChargeTime:number,
      waitUnpaidTime:number,
      qrShopId:number,
      useHolidayCalenderFlg:number
    ): Promise<void> {
      if (!this.selectServer) { alert("物件を選択してください"); this.result = []; return; }
      if (!window.confirm('更新しますか？')) return
      this.$vloading.show();
      const url = `${process.env.VUE_APP_API_URL_BASE}/api/web_ui/parking_mgr/${id}`;
      const formData = {
        serverId: this.selectServer,
        PARKING_NAME_1: parkingName01,
        PARKING_NAME_2: parkingName02,
        PARKING_NAME_3: parkingName03,
        PARKING_NAME_4: parkingName04,
        PARKING_NAME_5: parkingName05,
        PARKING_NAME_6: parkingName06,
        TEL: tel,
        ADDRESS: address,
        ID_4: id4,
        IP_ADDRESS: ipAddress,
        MYSQL_PORT: mysqlPort,
        SOCKET_PORT: socketPort,
        PARKING_NUMBER: parkingNumber,
        EMPTY_APPROVAL_TIME: emptyApprovalTime,
        SUPPORT_CENTER_CALL: supportCenterCall,
        ALERT_SIGNAL_TIME: alertSignalTime,
        EXIT_SIGNAL_TIME: exitSignalTime,
        WAIT_ENTRANT_TIME: waitEntrantTime,
        WAIT_EXIT_TIME: waitExitTime,
        SERVICE_ENTRANT_TIME: serviceEntrantTime,
        HOLIDAY_SERVICE_ENTRANT_TIME: holidayServiceEntrantTime,
        RE_CHARGE_TIME: reChargeTime,
        WAIT_UNPAID_TIME: waitUnpaidTime,
        QR_SHOP_ID: qrShopId,
        USE_HOLIDAY_CALENDER_FLG: useHolidayCalenderFlg,
      };
      return await axios
      .patch(url, formData)
      .then(() => {
        window.scroll({
          top: 0
        });
        alert("更新しました");
      })
      .catch((error: any) => {
        console.log(error)
        alert("更新に失敗しました");
      })
      .finally(() => {
        this.getParkingMgr()
        this.$vloading.hide();
      });
    },
    async getParkingMgr(): Promise<void> {
      if (!this.selectServer) { alert("物件を選択してください"); this.result = []; return; }
      this.$vloading.show();
      const url = `${process.env.VUE_APP_API_URL_BASE}/api/web_ui/parking_mgr`;
      return await axios
      .get(url, {params: { id: this.selectServer }} )
      .then((res:any) => {
        this.result = [];
        this.parkingName01 = [];
        this.parkingName02 = [];
        this.parkingName03 = [];
        this.parkingName04 = [];
        this.parkingName05 = [];
        this.parkingName06 = [];
        this.tel = [];
        this.address = [];
        this.id4 = [];
        this.ipAddress = [];
        this.mysqlPort = [];
        this.socketPort = [];
        this.parkingNumber = [];
        this.emptyApprovalTime = [];
        this.supportCenterCall = [];
        this.alertSignalTime = [];
        this.exitSignalTime = [];
        this.waitEntrantTime = [];
        this.waitExitTime = [];
        this.serviceEntrantTime = [];
        this.holidayServiceEntrantTime = [];
        this.reChargeTime = [];
        this.waitUnpaidTime = [];
        this.qrShopId = [];
        this.useHolidayCalenderFlg = [];
        this.result = res.data.flat()
        this.result.forEach(item => {
          this.parkingName01.push(item.PARKING_NAME_1);
          this.parkingName02.push(item.PARKING_NAME_2);
          this.parkingName03.push(item.PARKING_NAME_3);
          this.parkingName04.push(item.PARKING_NAME_4);
          this.parkingName05.push(item.PARKING_NAME_5);
          this.parkingName06.push(item.PARKING_NAME_6);
          this.tel.push(item.TEL);
          this.address.push(item.ADDRESS);
          this.id4.push(item.ID_4);
          this.ipAddress.push(item.IP_ADDRESS);
          this.mysqlPort.push(item.MYSQL_PORT);
          this.socketPort.push(item.SOCKET_PORT);
          this.parkingNumber.push(item.PARKING_NUMBER);
          this.emptyApprovalTime.push(item.EMPTY_APPROVAL_TIME);
          this.supportCenterCall.push(item.SUPPORT_CENTER_CALL);
          this.alertSignalTime.push(item.ALERT_SIGNAL_TIME);
          this.exitSignalTime.push(item.EXIT_SIGNAL_TIME);
          this.waitEntrantTime.push(item.WAIT_ENTRANT_TIME);
          this.waitExitTime.push(item.WAIT_EXIT_TIME);
          this.serviceEntrantTime.push(item.SERVICE_ENTRANT_TIME);
          this.holidayServiceEntrantTime.push(item.HOLIDAY_SERVICE_ENTRANT_TIME);
          this.reChargeTime.push(item.RE_CHARGE_TIME);
          this.waitUnpaidTime.push(item.WAIT_UNPAID_TIME);
          this.qrShopId.push(item.QR_SHOP_ID);
          this.useHolidayCalenderFlg.push(item.USE_HOLIDAY_CALENDER_FLG);
        });
      })
      .catch((error: any) => {
        console.log(error)
        alert("物件への接続に失敗しました");
        this.result = [];
      })
      .finally(() => {
        this.$vloading.hide();
      });
    },
  },
  computed: {
    selectServer() {
      return store.state.WebUI.server;
    }
  }
});
