




















































import Vue from "vue";
import axios from "axios";
import store from "/front/src/store/WebUI.ts";
interface Data {
  propsObject: any[];
  localName: any[];
  valid: boolean;
}
export default Vue.extend({
  data(): Data {
    return {
      propsObject: [],
      localName: [],
      valid: false,
    }
  },
  methods: {
    async send(id: number, ACCOUNT_ID: number, NAME: string): Promise<void> {
      if (!this.selectServer) { alert("物件を選択してください"); return; }
      if (!window.confirm('更新しますか？')) return
      this.$vloading.show();
      const url = `${process.env.VUE_APP_API_URL_BASE}/api/web_ui/account_mgr/${id}`;
      const formData = {
        serverId: this.selectServer,
        id: id,
        ACCOUNT_ID: ACCOUNT_ID,
        NAME: NAME
      };
      return await axios
      .patch(url, formData)
      .then(() => {
        alert("更新しました");
      })
      .catch((error: any) => {
        console.log(error)
        alert("更新に失敗しました");
      })
      .finally(() => {
        this.$emit("reload");
        this.$vloading.hide();
      });
    },
  },
  props:{
    localServerConfig: {},
    loading: {},
  },
  watch: {
    localServerConfig: {
      handler(newVal) {
        this.propsObject = newVal;
        this.localName = [];
        newVal.forEach(item => {
          // item[0] または item[0].NAME が存在しない場合はnullをpushする（ローカル接続不可対策）
          this.localName.push(item[0]?.NAME || null);
        });

      },
      deep: true, // オブジェクトの深い変更を監視
      immediate: true // 初回レンダリング時にも監視
    }
  },
  computed: {
    selectServer() {
      return store.state.WebUI.server;
    },
  },
});
