









import Vue from "vue";

export default Vue.extend({
  props: {
    selectObj: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      csvData: '',
    };
  },
  watch: {
    selectObj: {
      immediate: true,
      handler(newVal) {
        this.csvData = this.convertToCSV(newVal);
      },
    },
  },
  methods: {
    convertToCSV(objArray: Array<Record<string, any>>): string {
      if (objArray.length === 0) return '';
      // ヘッダーの取得
      const headers = Object.keys(objArray[0]);
      // 値のエスケープ処理
      const escapeValue = (value: any) => {
        const stringValue = String(value).replace(/"/g, '""'); // " をエスケープ
        if (stringValue.includes(',') || stringValue.includes('\n')) {
          return `"${stringValue}"`; // 値を " で囲む
        }
        return stringValue;
      };
      // データ行の取得
      const rows = objArray.map(obj => headers.map(header => escapeValue(obj[header])));
      // CSVの組み立て
      const csvContent = [
        headers.join(','), // ヘッダー行
        ...rows.map(row => row.join(',')) // データ行
      ].join('\n');
      return csvContent;
    },
    downloadCSV() {
      const blob = new Blob([this.csvData], { type: 'text/csv;charset=utf-8;' });
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = 'data.csv';
      link.click();
      URL.revokeObjectURL(link.href);
    },
  },
});
