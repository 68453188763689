













































































import Vue from "vue";
import axios from "axios";
import store from "/front/src/store/WebUI.ts";
interface Data {
  result: any[];
  email: string;
  registerParkingId:number;
  dialog: boolean;
  loading: boolean;
}
export default Vue.extend({
  data(): Data {
    return {
      result: [],
      email: "",
      dialog: false,
      loading: false,
    }
  },
  props:{
    alertGroupId: {}
  },
  watch: {
    selectServer(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.getAlertMailAddress(this.alertGroupId);
      }
    }
  },
  mounted() {
    this.getAlertMailAddress(this.alertGroupId);
  },

  methods: {
    createDialogOpen(parkingId:number){
      this.registerParkingId = parkingId
      this.dialog = true;
    },
    async create(mailAddress:string): Promise<void> {
      if (!window.confirm('登録しますか？')) return
      const url = `${process.env.VUE_APP_API_URL_BASE}/api/web_ui/alert_mail_address`;
      const formData = {
        SERVER_ID: this.selectServer,
        PARKING_ID: this.registerParkingId,
        ALERT_GROUP_ID: this.alertGroupId,
        MAIL_ADDRESS: mailAddress
      };
      return await axios
      .post(url, formData)
      .then(() => {
        this.dialog = false;
        alert("登録しました");
      })
      .catch((error: any) => {
        console.log(error)
        alert("登録に失敗しました");
      })
      .finally(() => {
        this.getAlertMailAddress(this.alertGroupId);
      });
    },
    async destroy(
      id:number,
      parkingId:number,
      mailAddress:string,
    ): Promise<void> {
      if (!window.confirm('削除しますか？')) return
      const url = `${process.env.VUE_APP_API_URL_BASE}/api/web_ui/alert_mail_address/${id}`;
      return await axios
      .delete(url, { params: {
        SERVER_ID: this.selectServer,
        PARKING_ID: parkingId,
        ALERT_GROUP_ID: this.alertGroupId,
        MAIL_ADDRESS: mailAddress
      } })
      .then(() => {
        alert("削除しました");
      })
      .catch((error: any) => {
        console.log(error)
        alert("削除に失敗しました");
      })
      .finally(() => {
        this.getAlertMailAddress(this.alertGroupId);
      });
    },
    async getAlertMailAddress(id:number): Promise<void> {
      console.log("getAlertMailAddress")
      this.result = [];
      if (this.selectServer) {
        this.loading = true;
        const url = `${process.env.VUE_APP_API_URL_BASE}/api/web_ui/alert_mail_address`;
        return await axios
        .get(url, { params: { id: this.selectServer, ALERT_GROUP_ID: id } })
        .then((res:any) => {
          console.log(res.data)
          this.result = res.data;
        })
        .catch((error: any) => {
          console.log(error)
          alert("物件への接続に失敗しました");
          this.result = [];
        })
        .finally(() => {
          this.loading = false;
        });
      }
    },
  },
  computed: {
    selectServer() {
      return store.state.WebUI.server;
    }
  }
});
