


































































import Vue from "vue";
import store from "/front/src/store/WebUI.ts";
export default Vue.extend({
  computed: {
    selectServer() {
      return store.state.WebUI.server;
    }
  }
});
