
















































































































import Vue from "vue";
import axios from "axios";
import store from "/front/src/store/WebUI.ts";
interface Data {
  kindId:number;
  kindItem:any[];
  note:string;
  labelId:number | null;
  valid: boolean;
  name: string;
  localPlace: string,
  localClassNumber: string,
  localKana: string,
  localCarNumber: string,
}
export default Vue.extend({
  data(): Data {
    return {
      kindId:1,
      kindItem:[
        { text:"字光式ナンバー", value:1 },
        { text:"枠・装飾・取付不正など", value:2 },
        { text:"その他", value:9 }
      ],
      note:"",
      valid: true,
      name: JSON.parse(localStorage.user).name,
      labelId:null,
      localPlace: this.place,
      localClassNumber: this.classNumber,
      localKana: this.kana,
      localCarNumber: this.carNumber,
    }
  },
  watch: {
    localPlace(input:string) {
      this.fullNumberPush(input);
    },
    localClassNumber(input:string) {
      this.fullNumberPush(input);
    },
    localKana(input:string) {
      this.fullNumberPush(input);
    },
    localCarNumber(input:string) {
      this.fullNumberPush(input);
    },
  },
  props:{
    createLoading:{},
    place:{},
    classNumber:{},
    kana:{},
    carNumber:{},
    readonlyFlg:{},
    labels:{}
  },
  methods: {
    fullNumberPush(input:string) {
      // 半角スペースがなく正しいナンバーの場合
      const pattern = /^([あ-ん]{3}|[一-鿐ヶ]{1,4})([1-9][0-9][0-9ACFHKLMPXY]|[1-9][0-9])([あ-えか-さす-ふほ-を])(\*\*\*[1-9]|\*\*[1-9][0-9]|\*[1-9][0-9][0-9]|[1-9][0-9][0-9][0-9])$/;
      const match = input.match(pattern);
      if (match) {
        this.localPlace = match[1];
        this.localClassNumber = match[2];
        this.localKana = match[3];
        this.localCarNumber =  match[4];
        return
      // 半角スペースがある場合
      } else {
        const regex = {
          place: /^[あ-ん]{3}$|^[一-鿐ヶ]{1,4}$/,
          class: /^[1-9][0-9][0-9ACFHKLMPXY]$|^[1-9][0-9]$/,
          kana: /^[あ-えか-さす-ふほ-を]$/,
          carNumber: /^\*\*\*[1-9]$|^\*\*[1-9][0-9]$|^\*[1-9][0-9][0-9]$|^[1-9][0-9][0-9][0-9]$/
        };
        // 半角スペースがある場合はそれで分割、なければ全体が1つの文字列
        const parts = input.split(' ').filter(Boolean);
        // 配列の要素が4つでなければ失敗
        if (parts.length !== 4) return ; // 失敗
        // それぞれのパーツが正しい正規表現に一致するか確認
        if (!parts[0].match(regex.place) ||
            !parts[1].match(regex.class) ||
            !parts[2].match(regex.kana) ||
            !parts[3].match(regex.carNumber)) {
          return // 失敗
        }
        // すべてのチェックをパスしたらオブジェクトを返す
        this.localPlace = parts[0];
        this.localClassNumber = parts[1];
        this.localKana = parts[2];
        this.localCarNumber = parts[3];
        return
      }
    },
    // 車両を登録する
    async create(
      place:string,
      classNumber:string,
      kana:string,
      carNumber:string,
      kindId:number,
      labelId:number,
      note:string,
      name:string
    ): Promise<void> {
      if (!this.$refs.form.validate()) return
      this.$emit('loading', true);
      if (!window.confirm('登録しますか？')) return;
      const url = `${process.env.VUE_APP_API_URL_BASE}/api/vehicle_notes`;
      const formData = {
        PLACE: place,
        CLASS_NUMBER: classNumber,
        KANA: kana,
        CAR_NUMBER: carNumber,
        NOTE_KIND_ID: kindId,
        NOTE: note,
        LAST_UPDATER_LABEL_ID: labelId,
        LAST_UPDATER_USER_ID: 2, // commons Userとしての固定値
        LAST_UPDATER_NAME: name,
        LAST_UPDATE_DATE: new Date()
      }
      return await axios
      .post(url, formData)
      .then((res:any) => {
        if (res.data.error){
          alert(res.data.message);
          return;
        }; 
        this.localPlace = "";
        this.localClassNumber = "";
        this.localKana = "";
        this.localCarNumber = "";
        this.kindId = 1;
        this.note = "";
        this.$emit('reload');
        this.$emit('close');
        alert("登録しました");
      })
      .catch((error: any) => {
        alert(error);
      })
      .finally(() => {
        this.$emit('loading', false);
      });
    },
  },
  computed: {
    selectServer() {
      return store.state.WebUI.server;
    }
  }
});
