

























































import Vue from "vue";
import axios from "axios";
import store from "/front/src/store/WebUI.ts";
interface Data {
  result: any[];
  loading: boolean;
  yesNo: any[];
}
export default Vue.extend({
  data(): Data {
    return {
      result: [],
      loading: false,
      yesNo: [
        { text:"0:しない", value: 0 },
        { text:"1:する", value: 1 }
      ]
    }
  },
  props:{
    alertGroupId: {}
  },
  watch: {
    selectServer(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.getAlertInformation(this.alertGroupId);
      }
    }
  },
  mounted() {
    this.getAlertInformation(this.alertGroupId);
  },
  methods: {
    // 更新実行
    async send(
      id:number,
      alertInfoId:number,
      errorFlag:number,
      alertFlag:number,
      alertTitle:string
    ): Promise<void> {
      if (!window.confirm('更新しますか？')) return
      const url = `${process.env.VUE_APP_API_URL_BASE}/api/web_ui/alert_information/${id}`;
      const formData = {
        SERVER_ID: this.selectServer,
        PARKING_ID: id,
        ALERT_GROUP_ID: this.alertGroupId,
        ALERTINFO_ID: alertInfoId,
        ERROR_FLAG: errorFlag,
        ALERT_FLAG: alertFlag,
        ALERT_TITLE: alertTitle
      };
      return await axios
      .patch(url, formData)
      .then(() => {
        alert("更新しました");
        this.getAlertInformation(this.alertGroupId);
      })
      .catch((error: any) => {
        console.log(error)
        alert("更新に失敗しました");
      });
    },
    async getAlertInformation(id:number): Promise<void> {
      console.log("getAlertInformation")
      this.result = [];
      if (this.selectServer) {
        this.loading = true;
        const url = `${process.env.VUE_APP_API_URL_BASE}/api/web_ui/alert_information`;
        return await axios
        .get(url, { params: { id: this.selectServer, ALERT_GROUP_ID: id } })
        .then((res:any) => {
          this.result = res.data;
        })
        .catch((error: any) => {
          console.log(error)
          alert("物件への接続に失敗しました");
          this.result = [];
        })
        .finally(() => {
          this.loading = false;
        });
      }
    },
  },
  computed: {
    selectServer() {
      return store.state.WebUI.server;
    }
  }
});
